<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <h1 v-if="$can('manage', 'settings')">Can Manage Settings</h1>
      <b-row class="justify-content-center">
        <b-col md="6">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form>
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <b-alert :show="(errorMessage != null)" variant="danger">{{ errorMessage }}</b-alert>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend><b-input-group-text><i class="icon-user"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input type="text" class="form-control" placeholder="Username" autocomplete="username email" v-model="username" />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend><b-input-group-text><i class="icon-lock"></i></b-input-group-text></b-input-group-prepend>
                    <b-form-input type="password" class="form-control" placeholder="Password" autocomplete="current-password" v-model="password" @keyup.enter.native="login"/>
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button variant="primary" class="px-4" @click="login">Login</b-button>
                    </b-col>
                    <b-col cols="6" class="text-right">
                      <b-button variant="link" class="px-0">Forgot password?</b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Login',
  data () {
    return {
      username: null,
      password: null,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'global/notifications'
    })
  },
  watch: {
    notifications: {
      handler: function (notifications) {
        if (notifications.length > 0) {
          let notification = notifications[notifications.length - 1]
          this.errorMessage = notification.text
          this.$store.dispatch('global/ackNotification')
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    login (e) {
      this.errorMessage = null
      e.preventDefault()
      this.$store.dispatch('global/login', {
        username: this.username,
        password: this.password
      })
      this.$snotify.clear()
      // this.$router.push({name: 'dashboard'})
    }
  }
}
</script>
